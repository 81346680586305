import React, { useState } from "react";
import useWeb3 from "../hooks/useWeb3";
import useProofs from "../hooks/useProofs";
import Layout from "../components/layout";
import ConnectWallet from "../views/connectWallet";
import GoldenCircle from "../views/goldenCircle"

export default function GoldenCirclePage () {
    /** WEB 3 */
    const [
        provider,
        signer,
        address,
        contractNFT,
        contractSales,
        isConnected,
        isCorrectChain,
        tryAutoConnect,
        triedAutoConnecting,
        setTriedAutoConnecting,
        connect,
        isConnecting,
      ] = useWeb3();

    const [
        wl_1_proofs,
        wl_2_proofs,
        isGoldenCircle,
        isWhiteList,
        returnProofsOfUser
    ] = useProofs(address);

    /**/

    return (
       <Layout>
            <ConnectWallet
                connect={connect}
                isConnected={isConnected}
            />
            <GoldenCircle
                contractSales={contractSales}
                signer={signer}
                provider={provider}
                address={address}
                isWhiteListed={isGoldenCircle}
                proofs={wl_1_proofs}
            />
        </Layout>
    )
}