import React from "react"

export default function ConnectWallet({ connect, isConnected }) {
    return <>
        {
            !isConnected &&
            <div className="container">
                <div className="main-banner__light_3"></div>
                <div className="main-banner__light_4"></div>
                <div className="main-banner__wrap">
                    <div className="main-banner__left">                        
                        <div className="main-banner__title">
                            Connect your wallet to mint
                        </div>
                        <form className="main-banner__form">                            
                            <button className="main-banner__button" onClick={(e) => {e.preventDefault(); connect()}}>
                                connect to wallet
                            </button>
                        </form>
                    </div>
                    <div className="main-banner__right">
                        <div className="main-banner__bot-img"></div>
                    </div>
                </div>
            </div>
        }
    </>
}