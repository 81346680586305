const Config = {
    DATA: {
        GOLDEN_CIRCLE_START: 1646074800,
        WHITELIST_START: 1646334000,
        PUBLIC_START: 1646679600
    },
    CONTRACTS: {
        NFT: "MetaBotSocietyERC721",
        SALES: "MetaBotSocietyPreSaleLimitedContract"
    },
    NETWORK: {
        TARGET_CHAIN_ID: 1,
        CHAIN_ID_TO_NAME: {
            "1": "mainnet",
            "3": "ropsten",
            "4": "rinkeby"
        },
        INFURA_ID: "c6eba4e364f745128c584a4024c33cf1",
        PORTIS_ID: "",
        FORTMATIC_ID: ""
    },
    ERRORS: {
        MESSAGES: [
            {
                error: "HAVE TO BUY AT LEAST 1",
                message: "Error: Your transaction has not gone through. You have to buy at least one ticket"
            },
            {
                error: "MetaMask Tx Signature: User denied transaction signature",
                message: "Error: You rejected the transaction."
            },
            {
                error: "insufficient funds for gas * price + value",
                message: "Error: You don't have enough Ether in your wallet to complete the transaction. The price for one METABOT is [amount] Ether.",
                insert: true
            },
            {
                error: "INVALID PROOF",
                message: "Error: You are not on the white list. Please contact the team if you think this is incorrect."
            }
        ]
    }
}

export default Config