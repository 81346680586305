import React, { useState } from "react";
import useGoldenCircleMint from "../hooks/useGoldenCircleMint";
import useIsStarted from "../hooks/useIsStarted";
import Config from "../config";
import { ethers } from "ethers";

const twoDecimals = str => {
    //console.log(str)
    const split = str.split(".");
    //console.log(split)
    if (split.length > 1) {
      return split[0] + "." + split[1].substring(0, 3);
    } else {
      return str;
    }
};

export default function WhiteList({ 
    contractSales,
    signer,
    provider,
    address,
    isWhiteListed,
    proofs 
}) {

    const [
        isDataLoaded,
        maxMintPerUser,
        minted,
        maxSupply,
        price,
        canUserMint,
        isSoldOut,
        mint,
        isMinting,
        error
    ] = useGoldenCircleMint(contractSales, signer, provider, address, proofs);

    const [
        now,
        isStarted,
        textDay,
        textHour,
        textMin,
        textSec,
    ] = useIsStarted(Config.DATA.GOLDEN_CIRCLE_START);

    const DataLoadingSection = () => (
        <>
            {
                !isDataLoaded() &&
                <div className="main-banner__left">           
                    <div className="main-banner__title">
                        Golden Circle METABOT SOCIETY Mint
                    </div>
                    <div className="main-banner__text">
                        <p>
                            Minting data is being loaded. This should take a few seconds.
                        </p>
                    </div>        
                </div>
            }
        </>
    )

    const NotStartedSection = () => (
        <>
            {
                isDataLoaded() && !isStarted() &&
                <div className="main-banner__left">                    
                    <div className="main-banner__title">
                        Golden Circle minting starts at February 28th 2022
                    </div>   
                    <div className="main-banner__text">
                        <p>
                            Come back in {textDay} days {textHour} hours {textMin} minutes and {textSec} seconds
                        </p>
                    </div>                    
                </div>
            }
        </>
    )

    const [amount, setAmount] = useState(1);

    const incrementAmount = () => {
        console.log("increment")
        if (amount < maxMintPerUser()) {
            setAmount(a => a + 1)
        }
    }

    const decrementAmount = () => {
        if (amount > 1) {
            setAmount(a => a - 1)
        }
    }      

    const UserMintSection = () => (
        <>
            {
                isDataLoaded() && isStarted() && !isSoldOut() && canUserMint() &&
                <div className="main-banner__left">
                    <div className="main-banner__title">
                        mint a METABOT
                    </div>
                    {
                        !isMinting &&
                        <form className="main-banner__form">
                        <div className="main-banner__input-row">
                            <div className="main-banner__input-wrap">
                                <label>Qty.</label>
                                <div className="main-banner__number-input-wrap">
                                    <div className="main-banner__input-minus" onClick={(e)  => { e.preventDefault(); decrementAmount(); }}></div>
                                    <input type="number" className="main-banner__input number-input" value={amount} onChange={() => 1} />
                                    <div className="main-banner__input-plus" onClick={(e)  => { e.preventDefault(); incrementAmount(); }}></div>
                                </div>
                            </div>
                            <div className="main-banner__input-wrap">
                                <label>Price (eth)</label>
                                <input id="main-banner-price" className="main-banner__input" disabled value={twoDecimals(ethers.utils.formatEther(price.mul(amount).toString()))}/>
                            </div>
                        </div>
                        <button className="main-banner__button" onClick={(e) => {e.preventDefault(); mint(amount)}}>
                            Mint
                        </button>
                        </form>
                    }
                    {
                        isMinting && !error &&
                        <div className="main-banner__text">
                            <p>
                                Your mint is loading. You can check the progess in your wallet on or Etherscan.
                            </p>
                        </div>
                    }
                    {
                        error && 
                        <div className="main-banner__text">
                            <p style={{color: "red"}}>
                                {error}
                            </p>
                        </div>
                    }
                </div>
            }
        </>
    )

    const UserMintedSection = () => (
        <>
            {
                isDataLoaded() && isStarted() && !isSoldOut() && !canUserMint() &&
                <div className="main-banner__left">           
                    <div className="main-banner__title">
                        Golden Circle METABOT SOCIETY Mint
                    </div>
                    <div className="main-banner__text">
                        <p>
                            Thank you for minting METABOT SOCIETY
                        </p>
                    </div>        
                </div>
            }
        </>
    )

    const SoldOutSection = () => (
        <>
            {
                isDataLoaded() && isStarted() && isSoldOut() &&
                <div className="main-banner__left">                              
                    <div className="main-banner__title">
                        Golden Circle METABOT SOCIETY Mint
                    </div>
                    <div className="main-banner__text">
                        <p>
                            The Golden Circle mint is sold out!
                        </p>
                    </div>        
                </div>
            }
        </>
    )

    const MintedSection = () => (
        <>
            {
                isDataLoaded() &&   
                <div className="main-banner__left">           
                    <div className="main-banner__title">
                        METABOT SOCIETY Whitelist Mint
                    </div>
                    <div className="main-banner__text">
                        <p>
                            The Whitelist Mint is closed
                        </p>
                    </div>        
                </div>
            }
        </>
    )


    return <>
        {
            isDataLoaded() &&

            (isWhiteListed() ?
            <div className="container">
                <div className="main-banner__light_3"></div>
                <div className="main-banner__light_4"></div>
                <div className="main-banner__wrap">
                    {/*<DataLoadingSection />
                    <NotStartedSection />
                    <UserMintSection />
                    <UserMintedSection />
                    <SoldOutSection />*/}
                    <MintedSection />
                    <div className="main-banner__right">
                        <div className="main-banner__bot-img"></div>
                    </div>
                </div>
            </div>
            :
            <div className="container">
                <div className="main-banner__light_3"></div>
                <div className="main-banner__light_4"></div>
                <div className="main-banner__wrap">
                    <div className="main-banner__left">                    
                        <div className="main-banner__title">
                            You are not part of the Golden Circle
                        </div>   
                        <div className="main-banner__text">
                            <p>
                                
                            </p>
                        </div>                    
                    </div>
                    <div className="main-banner__right">
                        <div className="main-banner__bot-img"></div>
                    </div>
                </div>
            </div>)
        }
    </>
}