import React from "react";

export default function Layout({ children }) {
    return (
        <>
        
       
            <header className="header">
                <div className="container">
                    <div className="header__wrap">
                        <a href="/" className="header__logo">
                            <img alt="logo" src="img/logo.svg" />
                        </a>
                        {/*<nav className="header__menu">
                            <ul>
                                <li>
                                    <a href="#">
                                        About us
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        NFT Rarity
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        Roadmap
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        team
                                    </a>
                                </li>
                            </ul>
                        </nav>
                        <div className="header__social">
                            <ul>
                                <li>
                                    <a href="#">
                                        <img alt="icon" src="img/instagram.svg"/>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <img alt="icon" src="img/discord.svg"/>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <img alt="icon" src="img/twitter.svg"/>
                                    </a>
                                </li>
                            </ul>
    </div>*/}
                    </div>
                </div>
            </header>

            <main className="main">
                <section className="main-banner sale-main-banner">
                    { children }
                </section>
            </main>

            <footer className="footer">
                <div className="container">
                    <div className="footer__wrap">
                        <a className="footer__logo" href="/">
                            <img alt="icon" src="img/logo.svg"/>
                        </a>
                    </div>
                    <div className="footer__bottom">
                        Copyright © SESA LABS 2021. All Rights Reserved.
                    </div>
                </div>
            </footer> 
        
        </>
    )
}