import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Public from "./pages/public.jsx"
import GoldenCircle from "./pages/goldencircle.jsx";
import WhiteList from './pages/whitelist.jsx';
import WlPublic from "./pages/wlpublic.jsx"

function App() {
  return (
    <div>
        <BrowserRouter>
            <Routes>
                <Route
                    path={"/goldencircle"}
                    element={<GoldenCircle />}
                />
                <Route
                    path={"/whitelist"}
                    element={<WlPublic />}
                />
                <Route
                    path={"/"}
                    element={<Public />}
                />
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
