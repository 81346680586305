import { useState, useEffect } from 'react';
import WhiteListProofs from "../proofs/wl_proofs.json"

export default function useProofs(address) {
    const [wl_1_proofs, set_wl_1_proofs] = useState(undefined);
    const [wl_2_proofs, set_wl_2_proofs] = useState(undefined);

    useEffect(() => {  
        if (address) {
            let _wl_1 = (WhiteListProofs.proofs[address.toLowerCase()] && WhiteListProofs.proofs[address.toLowerCase()]["1"]) || []
            let _wl_2 = (WhiteListProofs.proofs[address.toLowerCase()] && WhiteListProofs.proofs[address.toLowerCase()]["2"]) || []
            
            set_wl_1_proofs(_wl_1);
            set_wl_2_proofs(_wl_2);
        }
    }, [address])

    function isGoldenCircle() {
        return wl_1_proofs && wl_1_proofs.length > 0
    }

    function isWhiteList() {
        return wl_2_proofs && wl_2_proofs.length > 0
    }

    function returnProofsOfUser() {
        if (isGoldenCircle())
            return wl_1_proofs;
        else if (isWhiteList())
            return wl_2_proofs;
        else
            return undefined
    }

    return [
        wl_1_proofs,
        wl_2_proofs,
        isGoldenCircle,
        isWhiteList,
        returnProofsOfUser
    ]
}